(function () {
    'use strict';

    angular.module('adminApp').controller('VendorController', VendorController);

    VendorController.$inject = ['$scope', '$templateCache', '$filter', 'aerosAdminApi', 'vendors',
        'AddAdminVendorModal', 'AddAdminVendorLogoModal'];

    function VendorController($scope, $templateCache, $filter, aerosAdminApi, vendors, AddAdminVendorModal,
                              AddAdminVendorLogoModal) {

        angular.extend($scope, {
            openAddAdminVendorModal: openAddAdminVendorModal,
            openAddAdminVendorLogoModal: openAddAdminVendorLogoModal,
            loadVendors: loadVendors
        });

        (function prepareAgGrid() {
            var defaultWidth = 140,
                template = $templateCache.get('vendorGridActions.html'),
                addressInfoTemplate = $templateCache.get('addressInfo.html'),
                vendorLogoTemplate = $templateCache.get('vendorLogoItem.html');


            var columnDefs = [
                {
                    headerName: 'ID', field: 'id', hide: true
                }, {
                    headerName: 'Name', field: 'name', width: defaultWidth
                }, {
                    headerName: 'Contact Info',
                    children: [{
                        headerName: 'Contact Name',
                        field: 'contactName',
                        width: defaultWidth
                    }, {
                        headerName: 'Phone',
                        field: 'phone',
                        width: defaultWidth
                    }, {
                        headerName: 'Address',
                        field: 'address',
                        width: defaultWidth,
                        template: addressInfoTemplate
                    }, {
                        headerName: 'Website',
                        field: 'website',
                        width: defaultWidth
                    }, {
                        headerName: 'Email',
                        field: 'email',
                        width: defaultWidth
                    }]
                }, {
                    headerName: 'Logo',
                    field: 'logoUrl',
                    width: defaultWidth,
                    template: vendorLogoTemplate
                }, {
                    headerName: 'Actions',
                    field: 'actions',
                    template: template,
                    width: 140
                }
            ];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                enableSorting: true,
                enableFilter: true,
                rowSelection: 'multiple',
                enableColResize: true,
                angularCompileRows: true,
                rowHeight: 132,
                onRowClicked: function ($event) {
                }
            };


        })();

        $scope.$watch("commonReady", function (newVal) {

    		if (!$scope.allow['editVendors']) return $scope.reject();

            $scope.vendors = vendors.data.vendors;
            loadVendors();

        });

        function loadVendors() {

            $scope.rowData = [];

            angular.forEach($scope.vendors, function (vendor) {

                $scope.rowData.push({
                    id: vendor.id,
                    name: vendor.name,
                    contactName: vendor.contactInfo ? vendor.contactInfo.contactName : "",
                    phone: vendor.contactInfo ? vendor.contactInfo.phone : "",
                    website: vendor.contactInfo ? vendor.contactInfo.website : "",
                    email: vendor.contactInfo ? vendor.contactInfo.email : "",
                    address: vendor.contactInfo ? vendor.contactInfo.address : {},
                    logoUrl: vendor.logoUrl ? vendor.logoUrl : undefined
                });

            });

            var filteredRowData = $filter('filter')($scope.rowData, {name: $scope.queryString});

            $scope.gridOptions.api.setRowData(filteredRowData);
        }

        function prepareAddressString(address) {
            return "City: " + address.city + "<br>"
                + address.country;
        }

        function openAddAdminVendorModal(vendor) {

            if (!vendor) {
                AddAdminVendorModal.open().then(function (modalResults) {
                    aerosAdminApi.vendorApi.list(true).then(function (results) {
                        $scope.vendors = results.data.vendors;
                        loadVendors();

                        if (modalResults.uploadLogo) {

                            var vendor = $scope.vendors.find(function(item){
                                return item.id === modalResults.vendorId;
                            });

                            openAddAdminVendorLogoModal(vendor);
                        }
                    })
                });
            } else {
                var fullVendor = $scope.vendors.find(function (item) {
                    return item.id === vendor.id;
                });

                AddAdminVendorModal.open(fullVendor).then(function (modalResults) {
                    aerosAdminApi.vendorApi.list(true).then(function (results) {
                        $scope.vendors = results.data.vendors;
                        loadVendors();

                        if (modalResults.uploadLogo) {
                            openAddAdminVendorLogoModal(vendor);
                        }
                    })
                });
            }
        }

        function openAddAdminVendorLogoModal(vendor) {

            var fullVendor = $scope.vendors.find(function (item) {
                return item.id === vendor.id;
            });

            AddAdminVendorLogoModal.open(fullVendor).then(function () {
                aerosAdminApi.vendorApi.list(true).then(function (results) {
                    $scope.vendors = results.data.vendors;
                    loadVendors();
                })
            });
        }

    }

})();